export function find(language: string) {
  if (language == "en") {
  return {
    code: "OK",
    message: "La operación se completó exitosamente.",
    data: {
      id: 1,
      name: "AutoDay",
      order: 0,
      active: true,
      steps: [
        {
          id: 0,
          name: "1. General info",
          details: "",
          order: 0,
          step: 2,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 0,
              name: "How much would you like to pay biweekly?",
              key: "maxPriceId",
              type: "string",
              component: "radiobutton",
              catalog: "MaxPrice",
            },
            {
              id: 1,
              name: "When do you plan to buy your car?",
              key: "purchasePeriod",
              type: "string",
              component: "radiobutton",
              catalog: "PurchasePeriod",
            },
          ],
        },
        {
          id: 0,
          name: "2. Kind of car?",
          details: "",
          order: 0,
          step: 3,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 0,
              name: "What kind of car are you looking for?",
              key: "vehicleStatus",
              type: "string",
              component: "radiobutton",
              catalog: "vehicleStatus",
            },
          ],
        },
        {
          id: 0,
          name: "3. Credit or cash?",
          details: "",
          order: 0,
          step: 4,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 0,
              name: "How would you like to pay for your car?",
              key: "vehiclePaymentMethod",
              type: "string",
              component: "radiobutton",
              catalog: "payment",
            },
          ],
        },
        {
          id: 0,
          name: "4. Use of the car",
          details: "",
          order: 0,
          step: 5,
          maxAnswers: 4,
          required: true,
          answers: [
            {
              id: 0,
              name: "What kind of driver are you? (You can choose several)" ,
              key: "vehiclePurposes",
              type: "string",
              component: "check",
              catalog: "vehiclePurpose",
            },
          ],
        },
        {
          id: 0,
          name: "5. Size of the car",
          details: "",
          order: 0,
          step: 6,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 3,
              name: "How much space do you need?",
              key: "vehicleSizeId",
              type: "text",
              component: "radiobutton",
              catalog: "vehicleSize",
            },
          ],
        },
      ],
    },
  };
}
else {
  return {
    code: "OK",
    message: "La operación se completó exitosamente.",
    data: {
      id: 1,
      name: "AutoDay",
      order: 0,
      active: true,
      steps: [
        {
          id: 0,
          name: "1. Información general",
          details: "",
          order: 0,
          step: 2,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 0,
              name: "¿Cuánto te gustaría pagar bisemanalmente?",
              key: "maxPriceId",
              type: "string",
              component: "radiobutton",
              catalog: "MaxPrice",
            },
            {
              id: 0,
              name: "¿Cuándo planeas comprar tu coche?",
              key: "purchasePeriod",
              type: "string",
              component: "radiobutton",
              catalog: "PurchasePeriod",
            },
          ],
        },
        {
           id: 0,
          name: "2. Tipo de auto",
          details: "",
          order: 0,
          step: 3,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 0,
              name: "¿Qué tipo de auto estás buscando?",
              key: "vehicleStatus",
              type: "string",
              component: "radiobutton",
              catalog: "vehicleStatus",
            },
          ],
        },
        {
          id: 0,
          name: "3. Pago del auto",
          details: "",
          order: 0,
          step: 4,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 0,
              name: "¿Cómo deseas pagar tu auto?",
              key: "vehiclePaymentMethod",
              type: "string",
              component: "radiobutton",
              catalog: "payment",
            },
          ],
        },
        {
          id: 0,
          name: "4. Uso del auto" ,
          details: "",
          order: 0,
          step: 5,
          maxAnswers: 4,
          required: true,
          answers: [
            {
              id: 0,
              name: "¿Qué uso le darás a tu auto? (puedes elegir varias opciones)",
              key: "vehiclePurposes",
              type: "string",
              component: "check",
              catalog: "vehiclePurpose",
            },
          ],
        },
        {
          id: 0,
          name: "5. Tamaño del auto",
          details: "",
          order: 0,
          step: 6,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 3,
              name: "¿Cuánto espacio necesitas?",
              key: "vehicleSizeId",
              type: "text",
              component: "radiobutton",
              catalog: "vehicleSize",
            },
          ],
        },
      ],
    },
  };
}
}
export function find_Register(language: string) {
  if (language == "en") {
  return {
    code: "OK",
    message: "La operación se completó exitosamente.",
    data: {
      id: 1,
      name: "AutoDay",
      order: 0,
      active: true,
      steps: [
        {
          id: 0,
          name: "Contact Details",
          details: "",
          order: 1,
          step: 1,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 0,
              name: "Name",
              key: "name",
              type: "string",
              order: 1,
              component: "text",
            },
            {
              id: 1,
              name: "Mobile number",
              key: "phone",
              type: "string",
              order: 2,
              required: true,
              component: "phone",
            },
            {
              id: 2,
              name: "E-mail address",
              key: "email",
              order: 3,
              type: "string",
              component: "email",
              required: true,
            },
            {
              id: 3,
              name: "Postal Code",
              key: "postalCode",
              type: "string",
              component: "mask",
            },
            {
              id: 4,
              name: "I accept the terms and conditions of the service",
              key: "termsandconditions",
              type: "boolean",
              order: 6,
              required: true,
              component: "switch",
            },
            {
              id: 5,
              name: "I accept privacy policy",
              key: "privacyNotice",
              type: "boolean",
              order: 7,
              required: true,
              component: "switch",
            },
          ],
        },
      ],
    },
  };
}
else {
  return {
    code: "OK",
    message: "La operación se completó exitosamente.",
    data: {
      id: 1,
      name: "AutoDay",
      order: 0,
      active: true,
      steps: [
        {
          id: 0,
          name: "Datos de contacto",
          details: "",
          order: 1,
          step: 1,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 0,
              name: "Nombre",
              key: "name",
              type: "string",
              order: 1,
              component: "text",
            },
            {
              id: 1,
              name: "Número móvil",
              key: "phone",
              type: "string",
              order: 2,
              required: true,
              component: "phone",
            },
            {
              id: 2,
              name: "Correo electrónico",
              key: "email",
              order: 3,
              type: "string",
              component: "email",
              required: true,
            },
            {
              id: 3,
              name: "Código postal",
              key: "postalCode",
              type: "string",
              component: "mask",
            },
            {
              id: 4,
              name: "Acepto los términos y condiciones del servicio",
              key: "termsandconditions",
              type: "boolean",
              order: 6,
              required: true,
              component: "switch",
            },
            {
              id: 5,
              name: "Acepto el aviso de privacidad",
              key: "privacyNotice",
              type: "boolean",
              order: 7,
              required: true,
              component: "switch",
            },
          ],
        },
      ],
    },
  };
}
}
