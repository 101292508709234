import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"text-align":"center"},
  class: "my-5"
}
const _hoisted_2 = {
  key: 1,
  class: "row"
}
const _hoisted_3 = { class: "col-md-6 col-lg-6 col-sm-12" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_RadioButton = _resolveComponent("RadioButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_spinner, {
            text: "",
            color: _ctx.spinnerColor,
            textColor: _ctx.spinnerTextColor
          }, null, 8, ["color", "textColor"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.catalogData, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "input-container",
              key: item
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: _normalizeClass([
            _ctx.v$.name.$model == item.id
              ? 'field-checkbox-checked'
              : 'field-checkbox',
            {
              'p-invalid': _ctx.isRequired && _ctx.isSubmitted,
            },
          ]),
                  onClick: ($event: any) => (_ctx.check(item))
                }, [
                  _createVNode(_component_RadioButton, {
                    onChange: _ctx.setValues,
                    value: item.id,
                    modelValue: _ctx.v$.name.$model,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.name.$model) = $event))
                  }, null, 8, ["onChange", "value", "modelValue"]),
                  _createElementVNode("label", {
                    class: _normalizeClass([
              _ctx.v$.name.$model == item.id ? 'radio-label-checked' : 'radio-label',
              {
                'p-invalid': _ctx.isRequired && _ctx.isSubmitted,
              },
            ])
                  }, _toDisplayString(item.name), 3)
                ], 10, _hoisted_4)
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}