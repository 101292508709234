
import { defineComponent, reactive } from "vue";
import { required, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import InputText from "primevue/inputtext";

export default defineComponent({
  name: "TypeEmail",
  components: {
    InputText,
  },
  props: {
    label: String,
    validationLabel: String,
    isRequired: Boolean,
    isSubmitted: Boolean,
    answerKey: String,
    placeholder: String,
  },
  setup(props) {
    const f = reactive({
      name: "",
    });

    var rules;
    rules = props.isRequired
      ? (rules = {
          name: { email, required },
        })
      : (rules = {
          name: { email },
        });

    const v$ = useVuelidate(rules, f);
    return { f, v$ };
  },
  mounted() {
    var step = JSON.parse(sessionStorage.getItem("steps") || "{}");
    if (
      typeof step === "object" &&
      this.answerKey &&
      Object.prototype.hasOwnProperty.call(step, this.answerKey)
    )
      this.v$.name.$model = step[this.answerKey];
    this.setValues();
  },
  methods: {
    setValues() {
      this.$emit("getValue", {
        value: this.v$.name.$model || undefined,
        valid:
          !(!this.v$.name.$model && this.isRequired) && !this.v$.name.$invalid,
        answerKey: this.answerKey,
      });
    },
  },
});
