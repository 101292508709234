
import { defineComponent, reactive, ref } from "vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import RadioButton from "primevue/radiobutton";
import * as Catalog from "../api/helpers/Catalog";
import Spinner from "../components/Spinner.vue";
import _ from "underscore";

export default defineComponent({
  name: "TypeRadioButton",
  components: {
    RadioButton,
    Spinner,
  },
  props: {
    isRequired: Boolean,
    isSubmitted: Boolean,
    catalog: String,
    answerKey: String,
    label: String,
    language: String,
  },
  data() {
    return {
      spinnerColor: process.env.VUE_APP_SPINNER_COLOR,
      spinnerTextColor: process.env.VUE_APP_SPINNER_TEXT_COLOR,
    };
  },
  setup(props) {
    var loading = ref(true);
    var catalogData = ref({});
    const getCatalog = async () => {
      let language = sessionStorage.getItem("language");
      if (props.catalog == "vehicleStatus") {
        if (language == "en") {
          catalogData.value = [
            { id: 1, name: "Used car" },
            { id: 2, name: "New car" },
            { id: 3, name: "I don’t know yet" },
          ];
        } else if (language == "es") {
          catalogData.value = [
            { id: 1, name: "Auto usado" },
            { id: 2, name: "Auto nuevo" },
            { id: 3, name: "Aún no lo decido" },
          ];
        }
        loading.value = false;
      } else if (props.catalog == "payment") {
        if (language == "en") {
          catalogData.value = [
            { id: 1, name: "Credit" },
            { id: 2, name: "Cash" },
            { id:3, name: "I don’t know yet" }
          ];
        } else if (language == "es") {
          catalogData.value = [
            { id: 1, name: "A crédito" },
            { id: 2, name: "De contado" },
            { id:3, name: "Aún no lo decido" }
          ];
        }
        loading.value = false;
      } else if (props.catalog == "PurchasePeriod") {
        if (language == "en") {
          catalogData.value = [
            { id: 1, name: "This month" },
            { id: 2, name: "Next month" },
            { id: 3, name: "In 3 months" },
            { id: 4, name: "In more than 3 months" }
          ];
        } else if (language == "es") {
          catalogData.value = [
            { id: 1, name: "Este mes" },
            { id: 2, name: "El mes que entra" },
            { id: 3, name: "En 3 meses" },
            { id: 4, name: "En más de 3 meses" }
          ];
        }
        loading.value = false;
      } else if (props.catalog == "MaxPrice") {
        await Catalog._find(
          `/${props.catalog}?system=${process.env.VUE_APP_SYSTEM_ID}&order=id`
        ).then((res) => {
          if (language == "es") {
            res.data.data = _.map(res.data.data, (d) => {
              d.name = d.name.replace("Less than", "Menos de");
              d.name = d.name.replace("More than", "Mas de");
              d.name = d.name.replace("to", "a");
              return { id: d.id, name: d.name };
            });
          }
          catalogData.value = res.data.data;
          loading.value = false;
        });
      }
      else if (props.catalog == "vehicleSize") {
            await Catalog._find(
          `/${props.catalog}?system=${process.env.VUE_APP_SYSTEM_ID}&order=id`
        ).then((res) => {
          if (language == "es") {
              res.data.data = _.map(res.data.data, (d) => {
                d.name = d.name.replace("seats", "asientos");
                d.name = d.name.replace(" to "," a ");
                d.name = d.name.replace("More than", "Mas de");
                return { id: d.id, name: d.name };
              });
          }
          catalogData.value = res.data.data;
          loading.value = false;
        });
      }
      else {
        await Catalog._find(
          `/${props.catalog}?system=${process.env.VUE_APP_SYSTEM_ID}&order=id`
        ).then((res) => {
          catalogData.value = res.data.data;
          loading.value = false;
        });
      }
    };
    const f = reactive({
      name: null,
    });

    var rules;
    rules = props.isRequired
      ? (rules = {
          name: { required },
        })
      : (rules = {
          name: {},
        });

    if (props.isRequired) rules.name.required = required;

    const v$ = useVuelidate(rules, f);
    return { f, v$, catalogData, getCatalog, loading };
  },
  watch: {
    language(value) {
      this.getCatalog();
    },
  },
  mounted() {
    var step = JSON.parse(sessionStorage.getItem("steps") || "{}");
    if (
      typeof step === "object" &&
      this.answerKey &&
      Object.prototype.hasOwnProperty.call(step, this.answerKey)
    ){
      if(this.answerKey == 'vehiclePaymentMethod')
        this.v$.name.$model = step[this.answerKey] == 'A crédito' || step[this.answerKey] == 'Credit' ? 1
        : step[this.answerKey] == 'De contado' || step[this.answerKey] == 'Cash' ? 2 : 3;
      else
        this.v$.name.$model = step[this.answerKey];
    }
    this.getCatalog();
    this.setValues();
  },
  methods: {
    setValues() {
      let value = this.v$.name.$model;
      if(this.answerKey == 'vehiclePaymentMethod')
        value = this.v$.name.$model == 1 ? 'Credit'
        : this.v$.name.$model == 2 ? 'Cash' : 'I don’t know yet';
      this.$emit("getValue", {
        value: value || undefined,
        valid:
          !(!this.v$.name.$model && this.isRequired) && !this.v$.name.$invalid,
        answerKey: this.answerKey,
      });
    },
    check(item: any) {
      this.v$.name.$model = item.id;
      this.setValues();
    },
  },
});
