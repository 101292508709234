import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-float-label" }
const _hoisted_2 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_InputText, {
        inputmode: "email",
        onChange: _ctx.setValues,
        placeholder: _ctx.placeholder,
        modelValue: _ctx.v$.name.$model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.name.$model) = $event)),
        modelModifiers: { trim: true },
        class: _normalizeClass({ 'p-invalid': _ctx.v$.name.$invalid && _ctx.isSubmitted })
      }, null, 8, ["onChange", "placeholder", "modelValue", "class"])
    ]),
    (_ctx.v$.name.$invalid && _ctx.isSubmitted)
      ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString(_ctx.validationLabel), 1))
      : _createCommentVNode("", true)
  ]))
}