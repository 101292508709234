import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-float-label" }
const _hoisted_2 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputMask = _resolveComponent("InputMask")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_InputMask, {
        pattern: "[0-9A-Z]*",
        onChange: _ctx.setValues,
        placeholder: _ctx.$t('placeholder') + _ctx.label,
        modelValue: _ctx.v$.name.$model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.name.$model) = $event)),
        mask: _ctx.mask,
        class: _normalizeClass({ 'p-invalid': _ctx.v$.name.$invalid && _ctx.isSubmitted }),
        autoClear: false
      }, null, 8, ["onChange", "placeholder", "modelValue", "mask", "class"])
    ]),
    ((_ctx.v$.name.$model[0]==undefined || _ctx.v$.name.$model[1]=='_' || _ctx.v$.name.$model[2]=='_' || _ctx.v$.name.$model[4]=='_' || _ctx.v$.name.$model[5]=='_') && _ctx.isSubmitted)
      ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString(_ctx.validationLabel), 1))
      : _createCommentVNode("", true)
  ]))
}