
import { defineComponent, reactive } from "vue";
import InputMask from "primevue/inputmask";
import { required, numeric, minLength, minValue } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default defineComponent({
  name: "TypePhone",
  components: {
    InputMask,
  },
  props: {
    label: String,
    validationLabel: String,
    isRequired: Boolean,
    isSubmitted: Boolean,
    answerKey: String,
    minLength: Number,
    language: String
  },
  setup(props) {
    const f = reactive({
      name: "",
    });

    var rules;
    var placeholder = props.language == 'EN' ? `${props.minLength} digits` : `${props.minLength} dígitos`;
    var min = props.minLength || 10;
    var mask = "***-***";
     var minVal = "1";
    rules = props.isRequired
      ? (rules = {
          name: {
            numeric,
            minLength: minLength(min),
            minValue: minValue(minVal),
            required,
          },
        })
      : (rules = {
          name: {
            numeric,
            minLength: minLength(min),
            minValue: minValue(minVal),
          },
        });

    const v$ = useVuelidate(rules, f);
    return { f, v$, placeholder, mask };
  },
  mounted() {
    var step = JSON.parse(sessionStorage.getItem("steps") || "{}");
    if (
      typeof step === "object" &&
      this.answerKey &&
      Object.prototype.hasOwnProperty.call(step, this.answerKey)
    )
      this.v$.name.$model = step[this.answerKey];
    this.setValues();
  },
  methods: {
    setValues() {
      let value: string;
      value = this.v$.name.$model as string;
      value = value.replace("-","");
      this.v$.name.$model = value = value.toUpperCase();
      this.$emit("getValue", {
        value: value || undefined,
        valid:
          !((value[0]==undefined || value[1]=='_' || value[2]=='_' || value[4]=='_' || value[5]=='_') && this.isSubmitted),
        answerKey: this.answerKey,
      });
    },
  },
});
