
import { defineComponent, ref } from "vue";
import Checkbox from "primevue/checkbox";
//import Catalog from "@/models/Catalog";
import * as CatalogService from "../api/helpers/Catalog";
import Spinner from "../components/Spinner.vue";
import _ from "underscore";

export default defineComponent({
  name: "TypeText",
  components: {
    Checkbox,
    Spinner,
  },
  props: {
    label: String,
    validationLabel: String,
    isRequired: Boolean,
    answerKey: String,
    isSubmitted: Boolean,
    //catalog: Catalog,
    catalog: String,
    maxAnswers: Number,
    language: String,
  },
  setup(props, { emit }) {
    var loading = ref(true);
    const catalogData = ref([{ id: 0, name: "" }]);
    const showMessage = ref(false);
    const date = ref();
    const selectedcatalogData = ref([{}]);
    const selectedcatalogDatacheck = ref([{}]);
    selectedcatalogData.value.pop();
    selectedcatalogDatacheck.value.pop();
    selectedcatalogData.value.shift();
    //const catalogData = ref(props.catalog?.data);
    const getCatalog = async () => {
      let language = sessionStorage.getItem("language");
        await CatalogService._find(
          `/${props.catalog}?system=${process.env.VUE_APP_SYSTEM_ID}&order=name`
        ).then((res) => {
          if (props.catalog == "vehiclePurpose") {
            if (language == "es") {
              res.data.data = _.map(res.data.data, (d) => {
                d.id = d.systems[0].id;
                if (d.name == "Personal use") {
                  d.name = "Uso personal";
                }
                if (d.name == "Family with children") {
                  d.name = "Familia con hijos";
                }
                if (d.name == "Transportation service (Uber)")
                {
                  d.name = "Servicio de transporte (Uber)";
                }
                return { id: d.id, name: d.name };
              });
            }
            else {
              res.data.data = _.map(res.data.data, (d) => {
                d.id = d.systems[0].id;
                return { id: d.id, name: d.name };
              });
            }
          }
          catalogData.value = res.data.data;
          var step = JSON.parse(sessionStorage.getItem("steps") || "{}");
          if (
            typeof step === "object" &&
            props.answerKey &&
            Object.prototype.hasOwnProperty.call(step, props.answerKey)
          ) {
            selectedcatalogData.value = _.filter(catalogData.value, function(
              data
            ) {
              const id = data.id;
              return step[props.answerKey!].includes(id);
            });
            var value = _.filter(selectedcatalogData.value, function(num: any) {
              return num.id > 0;
            });
            value = _.map(value, function(num: any) {
              return num.id;
            });
            emit("getValue", {
              value: value.length > 0 ? value : "",
              valid: props.isRequired
                ? value.length > 0
                  ? true
                  : false
                : true,
              answerKey: props.answerKey,
            });
          }
          loading.value = false;
        });
    };
    return {
      showMessage,
      date,
      catalogData,
      selectedcatalogData,
      selectedcatalogDatacheck,
      getCatalog,
      loading,
    };
  },
  data() {
    return {
      checked: false,
      spinnerColor: process.env.VUE_APP_SPINNER_COLOR,
      spinnerTextColor: process.env.VUE_APP_SPINNER_TEXT_COLOR,
    };
  },
  watch: {
    language(value) {
      this.getCatalog();
    },
  },
  mounted() {
    this.getCatalog();
    this.setValues();
  },
  methods: {
    check(category: any) {
      if (!this.selectedcatalogDatacheck.includes(category)) {
        var value = _.filter(this.selectedcatalogData, function(num: any) {
          return num.id > 0;
        });
        if (
          !this.maxAnswers ||
          (this.maxAnswers && value.length < this.maxAnswers!)
        ) {
          this.selectedcatalogDatacheck.push(category);
          this.selectedcatalogData = this.selectedcatalogDatacheck;
        }
      } else {
        this.selectedcatalogData = _.filter(this.selectedcatalogData, function(
          num: any
        ) {
          return num.id != category.id;
        });
        this.selectedcatalogDatacheck = this.selectedcatalogData;
      }
      this.setValues();
    },
    setValues() {
      var value = _.filter(this.selectedcatalogData, function(num: any) {
        return num.id > 0;
      });
      if (this.maxAnswers && value.length > this.maxAnswers!) {
        this.selectedcatalogData.pop();
        return;
      }
      value = _.map(value, function(num: any) {
        return num.id;
      });
      this.$emit("getValue", {
        value: value.length > 0 ? value : "",
        valid: this.isRequired ? (value.length > 0 ? true : false) : true,
        answerKey: this.answerKey,
      });
    },
  },
});
