import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-2 col-3col-md-2 col-sm-3 col-xs-3 py-0 middle-label center switch" }
const _hoisted_3 = { class: "col-9 col-lg-10 col-md-10 col-sm-9 col-xs-9 pl-0 m-0 py-0 center" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  style: {"color":"white","text-decoration":"underline white"},
  href: "/aviso-de-privacidad"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  style: {"color":"white","text-decoration":"underline white"},
  href: "/terminos-y-condiciones"
}
const _hoisted_8 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_InputSwitch, {
        onChange: _ctx.setValues,
        modelValue: _ctx.v$.name.$model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.name.$model) = $event))
      }, null, 8, ["onChange", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", {
        class: _normalizeClass(["switch-label", [
          _ctx.isRequired && !_ctx.v$.name.$model && _ctx.isSubmitted
            ? 'p-error'
            : _ctx.v$.name.$model
            ? 'p-success'
            : '',
        ]]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => {
          _ctx.v$.name.$model = !_ctx.v$.name.$model;
          _ctx.setValues();
        })
      }, [
        (_ctx.label == 'I accept privacy policy')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.label), 1)
            ]))
          : (_ctx.label == 'I accept the terms and conditions of the service')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.label), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.label), 1))
      ], 2)
    ])
  ]))
}