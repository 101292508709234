
import { defineComponent, reactive, ref } from "vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import InputText from "primevue/inputtext";

export default defineComponent({
  name: "TypeText",
  components: {
    InputText,
  },
  props: {
    label: String,
    validationLabel: String,
    isRequired: Boolean,
    isSubmitted: Boolean,
    answerKey: String,
    placeholder: String
  },
  setup(props) {
    var rules;
    const f = reactive({
      name: "",
    });
    if (props.isRequired) {
      rules = {
        name: { required },
      };
    } else {
      rules = {
        name: {},
      };
    }

    const showMessage = ref(false);
    const date = ref();

    const v$ = useVuelidate(rules, f);

    return { f, v$, showMessage, date };
  },
  mounted() {
    var step = JSON.parse(sessionStorage.getItem('steps') || '{}');
    if (typeof step === "object" && this.answerKey) {
      this.v$.name.$model = step[this.answerKey];
    }
     this.setValues();
  },
  methods: {
    setValues() {
      this.$emit("getValue", {
        value: this.v$.name.$model,
        valid: !this.v$.name.$invalid,
        answerKey: this.answerKey,
      });
    },
  },
});
